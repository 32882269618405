<template>
  <div>
    <div class="work-title bg-white padding">
      <div class="margin-top-sm work-title-all flex justify-content-between aligin-items-center flex-wrap-wrap">
        <div class="work-title-content flex aligin-items-center">
          <el-popover width="200" trigger="click">
            <!-- <el-input v-model="queryList.open_school" placeholder="" size="mini" /> -->
            <el-checkbox-group v-model="queryList.open_school">
              <el-checkbox class="padding-xs" :label="item.id" v-for="(item, index) of schoolList" :key="index">{{ item.name }}</el-checkbox>
            </el-checkbox-group>
            <div slot="reference">
              <span>所属校区</span>
              <i class="el-icon-caret-bottom" />
              <i class="border-right" />
            </div>
          </el-popover>
          <el-popover width="150" trigger="click">
            <el-checkbox-group v-model="queryList.charge_type">
              <el-checkbox class="padding-xs" :label="0">按课时</el-checkbox>
              <el-checkbox class="padding-xs" :label="2">按期</el-checkbox>
              <el-checkbox class="padding-xs" :label="1">按时间</el-checkbox>
            </el-checkbox-group>
            <div slot="reference">
              <span>部门</span>
              <i class="el-icon-caret-bottom" />
              <span class="border-right" />
            </div>
          </el-popover>
    
          <el-popover width="150" trigger="click">
            <el-checkbox-group v-model="queryList.status">
              <el-checkbox class="padding-xs" :label="0">开售</el-checkbox>
              <el-checkbox class="padding-xs" :label="1">停售</el-checkbox>
            </el-checkbox-group>
            <div slot="reference">
              <span>课程状态</span>
              <i class="el-icon-caret-bottom" />
              <i class="border-right" />
            </div>
          </el-popover>
    
          <el-popover width="200" trigger="click">
            <el-checkbox-group v-model="queryList.curriculum_subject">
              <el-checkbox class="padding-xs" :label="item.id" v-for="(item, index) of SubjectTypeList" :key="index">{{ item.title }}</el-checkbox>
            </el-checkbox-group>
            <div slot="reference">
              <span>科目</span>
              <i class="el-icon-caret-bottom" />
              <span class="border-right" />
            </div>
          </el-popover>
          <el-popover width="150" trigger="click">
            <!-- <el-input v-model="classInput" placeholder="" size="mini" /> -->
            <el-checkbox-group v-model="queryList.curriculumseason">
              <el-checkbox class="padding-xs" :label="item.value" v-for="(item, index) of fourSeasons" :key="index">{{ item.label }}</el-checkbox>
            </el-checkbox-group>
            <div slot="reference">
              <span>学季</span>
              <i class="el-icon-caret-bottom" />
            </div>
          </el-popover>
        </div>
    
        <div class="work-title-search flex">
          <el-input placeholder="请输入姓名、电话搜索" v-model="queryList.KeyWord" class="input-with-select" size="mini" style="width:300px;"></el-input>
        </div>
      </div>
    </div>
    <as-list ref="reload" :ajaxMethods="{ list: 'accountQuery' }" :listQueryProp="queryList">
      <el-table-column align="center" prop="full_name" sortable label="姓名"></el-table-column>
      <el-table-column align="center" prop="" label="性别">
        <template slot-scope="scope">
          <span class="" v-if="scope.row.sex == 0">女</span>
          <span class="" v-if="scope.row.sex == 1">男</span>
          <span class="" v-if="scope.row.sex == 2">未知</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="post_type_name" label="职务名称"></el-table-column>
      <el-table-column align="center" prop="org_name" label="部门"></el-table-column>
      <el-table-column align="center" prop="phone" label="联系电话"></el-table-column>
      <el-table-column align="center" prop="curriculum_name" label="所属校区"></el-table-column>
    </as-list>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryList: {
        roleids: [],
        has_all: true,
        KeyWord: ''
      }
    };
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>

<style scoped></style>
